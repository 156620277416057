import Vue from 'vue';
import intersectionBy from 'lodash/intersectionBy';
import differenceBy from 'lodash/differenceBy';
import forIn from 'lodash/forIn';
import isArray from 'lodash/isArray';
import moment from 'moment-timezone';
import voucher_codes from 'voucher-code-generator';
import round from 'lodash/round';

const checkValidPhone = (event) => {
    const specialKeyCodes = [8, 9, 13, 46, 37, 39]; // backspace, enter, tab, delete, arrow left. arrow right
    const ctrlCombines = [65, 67, 86, 88]; // ctrl A, ctrl C, ctrl V, ctrl X
    const countryKeyCodes = [231]; // country keyboards on window: vietnamese
    if (!event.shiftKey &&
        ((event.keyCode >= 48 && event.keyCode <= 57) ||
            (event.keyCode >= 96 && event.keyCode <= 105) ||
            (event.ctrlKey && ctrlCombines.includes(event.keyCode)) ||
            specialKeyCodes.includes(event.keyCode) ||
            (countryKeyCodes.includes(event.keyCode) && [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(+event.key)))
    ) {
        return true;
    }
    return event.preventDefault();
};
const checkValidNumber = (event) => {
    const specialKeyCodes = [8, 9, 13, 46, 37, 39, 190]; // backspace, enter, tab, delete, arrow left. arrow right
    const ctrlCombines = [65, 67, 86, 88]; // ctrl A, ctrl C, ctrl V, ctrl X
    const countryKeyCodes = [231]; // country keyboards on window: vietnamese
    if (!event.shiftKey &&
        ((event.keyCode >= 48 && event.keyCode <= 57) ||
            (event.keyCode >= 96 && event.keyCode <= 105) ||
            (event.ctrlKey && ctrlCombines.includes(event.keyCode)) ||
            specialKeyCodes.includes(event.keyCode) ||
            (countryKeyCodes.includes(event.keyCode) && [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(+event.key)))
    ) {
        return true;
    }
    // if (event.target.value && Number(event.target.value) <= 100) return true;
    return event.preventDefault();
};

const handleOnPaste = (event) => {
    const value = parseFloat(event.clipboardData.getData('text'));
    if (value >= 0) return;
    if (value < 0 || isNaN(value)) return event.preventDefault();
};

const insert = (arr, index, newItem) => {
    return [...arr.slice(0, index), newItem, ...arr.slice(index)];
};

const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

const removeEmpty = (obj) => {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj;
};

const changeFieldName = (array, beforeName, afterName) => {
    if (!array.length) return;

    array.forEach((el) => {
        el[afterName] = el[beforeName];
        delete el[beforeName];
    });
    return array;
};

const getItemWithStatus = (defaultItems, beforeSubmitItem) => {
    //Get common Items of two Items array => Status: 0 (Unchanged)
    let commonItems = intersectionBy(defaultItems, beforeSubmitItem, 'id');
    //Get Items only in original Items array => Status: 2 (Deleted)
    let deletedItems = differenceBy(defaultItems, beforeSubmitItem, 'id');
    //Get Items only in current Items array
    let addNewItems = differenceBy(beforeSubmitItem, defaultItems, 'id');
    return [commonItems, deletedItems, addNewItems];
};

const getRandom = (arr, n) => {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError('getRandom: more elements taken than available');
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
};

const swapArrayLocs = (arr, from, to) => {
    let newArr = [...arr];
    let temp = newArr[from];

    newArr[from] = newArr[to];
    newArr[to] = temp;
    return newArr;
};

const getUrlExtension = (url) => {
    return url.split(/[#?]/)[0].split('.').pop().trim();
};

const checkInvalidVideoUrl = (url) => {
    return new Promise((resolve) => {
        if (!url) resolve(true);

        const fileType = getUrlExtension(url);
        const isValidInternalLink = ['mp4', 'mov', 'avi'].includes(fileType);

        const youtubeRegex = /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
        const isValidYoutubeLink = youtubeRegex.test(url);

        if (!isValidInternalLink && !isValidYoutubeLink) resolve(false);

        if (isValidInternalLink) {
            let video = document.createElement('video');
            video.setAttribute('src', url);
            video.addEventListener('canplay', function() {
                resolve(true);
            });
            video.addEventListener('error', function() {
                resolve(false);
            });
        } else {
            resolve(true);
        }
    });
};
const checkInvalidAudioUrl = (url) => {
    return new Promise((resolve) => {
        if (!url) resolve(true);

        const fileType = getUrlExtension(url);

        if (!['mp3', 'wav'].includes(fileType)) resolve(false);

        let audio = document.createElement('audio');
        audio.setAttribute('src', url);
        audio.addEventListener('canplay', function() {
            resolve(true);
        });
        audio.addEventListener('error', function() {
            resolve(false);
        });
    });
};

const formatDateLocale = (date, locale = 'vi-VN') => {
    return new Intl.DateTimeFormat(locale, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        timeZone: 'Asia/Ho_Chi_Minh',
    }).format(date);
};

// const formatDateLocale = (date, locale = 'vi-VN') => {
//     return new Intl.DateTimeFormat(locale, {
//         month: '2-digit',
//         day: '2-digit',
//         year: 'numeric',
//         hour: '2-digit',
//         minute: '2-digit',
//         hour12: false,
//         timeZone: 'Asia/Ho_Chi_Minh',
//     }).format(date);
// };

const formatDateLocaleCalendar = (date, options = {}, locale = 'vi-VN') => {
    return new Intl.DateTimeFormat(locale, {
        timeZone: 'Asia/Ho_Chi_Minh',
        ...options
    }).format(date);
};


const getTimeOfDate = (
    params = { date: new Date(), locale: 'vi-VN', showSecond: false },
) => {
    const option = {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Asia/Ho_Chi_Minh',
        hour12: false,
    };
    if (params.showSecond) option.second = 'numeric';
    return new Intl.DateTimeFormat(params.locale, option).format(params.date);
};

const convertTimeToSecond = (time) => {
        const [hh, mm, ss] = `${
    time.split(':').length === 3 ? time : `${time}:00`
  }`.split(':');
  return Number(hh) * 3600 + Number(mm) * 60 + Number(ss);
};

const convertToISODate = (date) => {
  const dateMoment = moment(date, 'DD-MM-YYYY');

  if (dateMoment.isValid()) return dateMoment.format('YYYY-MM-DD');

  return null;
};

const convertDateToTimestamp = (date, format = 'DD/MM/YYYY') => {
  const dateMoment = moment(date, format);
  if (dateMoment.isValid()) return Number(dateMoment.format('X'));

  return null;
};
const convertTimestampToDate = (timestamp, format = 'DD/MM/YYYY') => {
  const dateMoment = moment(timestamp);
  if (dateMoment.isValid()) return moment.unix(timestamp).format(format);

  return null;
};

const generateReferralCode = (length, count = 1) => {
  return voucher_codes.generate({
    length,
    count,
  });
};

const isEqualTwoObjects = (defaultObject, afterObject) => {
  let difficalValues = [];
  forIn(defaultObject, function (value, key) {
    if (isArray(afterObject[key])) return;

    if (afterObject[key] !== value) {
      difficalValues.push({ key: afterObject[key] });
    }
  });
  return difficalValues <= 0;
};

const disabledAfterToday = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return date > today;
};
const disabledBeforeToday = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return date < today;
};

const roundNumber = (number, digits = 1) => {
  return round(number, digits);
};

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

const getDurationMonth = (date) => {
  const today = moment(new Date(), 'YYYY/MM/DD');
  const dateFormart = moment.unix(date).format('YYYY/MM/DD');
  const a = moment(today);
  const b = moment(dateFormart);

  const years = a.diff(b, 'year');
  b.add(years, 'years');

  const months = a.diff(b, 'months');
  b.add(months, 'months');
  if (!years) return `${months} tháng`;
  return `${years} năm ${months} tháng`;
};

const mixin = {
  methods: {
    checkValidPhone,
    handleOnPaste,
    checkValidNumber,
    insert,
    delay,
    removeEmpty,
    changeFieldName,
    getItemWithStatus,
    getRandom,
    swapArrayLocs,
    checkInvalidVideoUrl,
    getUrlExtension,
    checkInvalidAudioUrl,
    formatDateLocale,
    formatDateLocaleCalendar,
    getTimeOfDate,
    convertTimeToSecond,
    convertToISODate,
    convertDateToTimestamp,
    convertTimestampToDate,
    generateReferralCode,
    isEqualTwoObjects,
    disabledAfterToday,
    disabledBeforeToday,
    roundNumber,
    paginate,
    getDurationMonth,
  },
};

Vue.mixin(mixin);

export {
  checkValidPhone,
  handleOnPaste,
  checkValidNumber,
  insert,
  delay,
  removeEmpty,
  changeFieldName,
  getItemWithStatus,
  getRandom,
  swapArrayLocs,
  checkInvalidVideoUrl,
  getUrlExtension,
  checkInvalidAudioUrl,
  getTimeOfDate,
  convertTimeToSecond,
  convertToISODate,
  convertDateToTimestamp,
  convertTimestampToDate,
  generateReferralCode,
  isEqualTwoObjects,
  disabledAfterToday,
  disabledBeforeToday,
  roundNumber,
  paginate,
  getDurationMonth,
};