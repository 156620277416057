export default {
    namespaced: true,
    state: {
        listCustomer: [
            { name: '', phoneNumber: '', contactSource: '', dateOfBirth: '', diseaseType: '', course: '', coach: '' }
        ],
        courses: [],
        coaches: [],
    },
    actions: {
        updateCustomer({ commit }, { index, customerReceiveData }) {
            commit('UPDATE_CUSTOMER', { index, customerReceiveData });
        },
        addCustomer({ commit }) {
            commit('ADD_CUSTOMER');
        },
        deleteCustomer({ commit }, index ) {
            commit('DELETE_CUSTOMER', index);
        },
        setCourses({ commit }, courses) {
            commit('SET_COURSES', courses);
        },
        setCoaches({ commit }, coaches) {
            commit('SET_COACHES', coaches);
        },
        resetListCustomer({ commit }) {
            commit('RESET_LISTCUSTOMER');
        }
    },
    mutations: {
        UPDATE_CUSTOMER(state, { index, customerReceiveData }) {
            state.listCustomer[index] = customerReceiveData;
        },
        ADD_CUSTOMER(state) {
            state.listCustomer.push({ name: '', phoneNumber: '', contactSource: '', dateOfBirth: '', diseaseType: '', course: '', coach: '' });
        },
        DELETE_CUSTOMER(state, index) {
            state.listCustomer.splice(index, 1);
        },
        SET_COURSES(state, courses) {
            state.courses = courses;
        },
        SET_COACHES(state, coaches) {
            state.coaches = coaches;
        },
        RESET_LISTCUSTOMER(state) {
            state.listCustomer = [];
        }
    },
}